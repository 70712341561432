import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class NotificationSettingService {
  private apiPrefix = '/client/notification-settings';

  constructor(
    private api: ApiService
  ) {
  }

  getSettings() {
    return this.api.get(`${this.apiPrefix}`);
  }

  updateSetting(id: number, body: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, body);
  }

}
